import "./App.css";
import CaseDetailsPage from "./pages/CaseDetailsPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AccountsPage from "./pages/AccountsPage";
import DashboardLayout from "./layouts/DashboardLayout";
import CouponPage from "./pages/CouponPage";
import NewsAnalyzerPage from "./pages/NewsAnalyzerPage";
// import LoginPage from "./pages/LoginPage";
// import "semantic-ui-css";

const CasesRoute = () => {
  return (
    <DashboardLayout>
      <CaseDetailsPage />
    </DashboardLayout>
  );
};

const AccountsRoute = () => {
  return (
    <DashboardLayout>
      <AccountsPage />
    </DashboardLayout>
  );
};
const CouponsRoute = () => {
  return (
    <DashboardLayout>
      <CouponPage />
    </DashboardLayout>
  );
};


const NewsAnalyzerRoute = () => {
  return (
      <NewsAnalyzerPage />
  );
};
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={CasesRoute} />
        <Route exact path="/accounts" component={AccountsRoute} />
        <Route exact path="/coupons" component={CouponsRoute} />
        <Route exact path="/news-analyzer" component={NewsAnalyzerRoute} />
      </Switch>
    </Router>
  );
}

export default App;
