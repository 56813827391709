import React from "react";
import CaseDetails from "../components/CaseDetails";
const CaseDetailsPage = () => {
  return (
    <div>
      <CaseDetails />
    </div>
  );
};
export default CaseDetailsPage;
