import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "semantic-ui-react";
import CaseDetailListItems from "./CaseDetailListItems";
const CaseDetailList = ({ payload, fetchMore, limit, hasMore }) => {
  return (
    <InfiniteScroll
      dataLength={payload.length} //This is important field to render the next data
      next={() => {
        fetchMore({
          variables: {
            page: parseInt(payload.length / limit, 10) + 1,
            limit,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              getCases: {
                ...prev.getCases,
                payload: [
                  ...prev.getCases.payload,
                  ...fetchMoreResult.getCases.payload,
                ],
              },
            });
          },
        });
      }}
      hasMore={hasMore}
      loader={
        <Loader active inline="centered" style={{ overflow: "hidden" }} />
      }
      scrollableTarget="scrollableDiv"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      {payload.map((pay, i) => (
        <CaseDetailListItems key={i} pay={pay} />
      ))}
    </InfiniteScroll>
  );
};
export default CaseDetailList;
