import { gql } from "@apollo/client";
export const GET_CASES = gql`
  query getCases(
    $page: Int
    $limit: Int
    $filter: CaseFilter
    $search: CaseSearch
  ) {
    getCases(page: $page, limit: $limit, filter: $filter, search: $search) {
      payload {
        _id
        entryType
        state
        district
        location
        estCode
        link
        year
        caseName
        advocates
        caseType
        accountIds
        cNRNumber
        filingNumber
        filingDate
        registrationNumber
        registrationDate
        complainantName
        complainantName
        respondentName
        respondentAdvocate
        history {
          businessOnDate
          nextHearingDate
          stage
          judge
        }
        createdAt
        updatedAt
      }
      count
      page
      limit
      status
      message
    }
  }
`;
