import { useQuery } from "@apollo/client";
import React from "react";
import moment from "moment";
import { Button, Form, Loader, Segment, Table } from "semantic-ui-react";
import { Accounts } from "../apollo/Queries/Accounts";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

const AccountDetails = () => {
  let page = 1;
  let limit = 20;
  const { data, loading, error, fetchMore } = useQuery(Accounts, {
    variables: {
      page,
      limit,
    },
  });
  if (loading) return <Loader active inline="centered" />;
  if (error) return <h3>{error.message}</h3>;
  const payload = data?.accounts?.payload;
  const hasMore = data?.accounts?.count > payload?.length;
  return (
    <React.Fragment>
      <Form>
        <div style={styles.tableContainer}>
          <Segment>
            <InfiniteScroll
              dataLength={payload?.length} //This is important field to render the next data
              next={() => {
                fetchMore({
                  variables: {
                    page: parseInt(payload?.length / limit, 10) + 1,
                    limit,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      accounts: {
                        ...prev.accounts,
                        payload: [
                          ...prev.accounts.payload,
                          ...fetchMoreResult.accounts.payload,
                        ],
                      },
                    });
                  },
                });
              }}
              hasMore={hasMore}
              loader={
                <Loader
                  active
                  inline="centered"
                  style={{ overflow: "hidden" }}
                />
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="11">
                      <span
                        style={{
                          letterSpacing: 1,
                          fontSize: 15,
                        }}
                      >
                        Accounts
                      </span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="2">Name</Table.Cell>
                    <Table.Cell colSpan="2">Email</Table.Cell>
                    <Table.Cell colSpan="2">Contact Number</Table.Cell>
                    <Table.Cell colSpan="2">Location</Table.Cell>
                    <Table.Cell colSpan="2">Cases</Table.Cell>
                    <Table.Cell colSpan="2">Date</Table.Cell>
                  </Table.Row>

                  {payload?.map((account, i) => (
                    <Table.Row key={i}>
                      <Table.Cell colSpan="2">{account.name}</Table.Cell>
                      <Table.Cell colSpan="2">{account.email}</Table.Cell>
                      <Table.Cell colSpan="2">{account.number}</Table.Cell>

                      <Table.Cell colSpan="2">{account.location}</Table.Cell>

                      <Table.Cell colSpan="2">{account.caseCount}</Table.Cell>
                      <Table.Cell colSpan="2">
                        {moment(parseInt(account.createdAt)).format(
                          "DD/MM/YYYY"
                        )}{" "}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </Segment>
        </div>
      </Form>
    </React.Fragment>
  );
};

const styles = {
  tableContainer: {
    marginTop: 35,

    paddingLeft: 10,
    height: "80vh",
  },
};
export default AccountDetails;
