import { gql } from "@apollo/client";
export const Places = gql`
  query places($page: Int, $limit: Int, $search: String) {
    places(page: $page, limit: $limit, search: $search) {
      payload {
        _id
        name
        state
        district
        entryType
      }
    }
  }
`;
