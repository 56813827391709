import { gql } from "@apollo/client";

export const Coupons = gql`
  query coupons($page: Int, $limit: Int, $filter: CouponFilter) {
    coupons(page: $page, limit: $limit, filter: $filter) {
      payload {
        _id
        name
        prefix
        amount
        minTxnLimit
        used
        createdAt
        updatedAt
      }
      count
      page
      limit
    }
  }
`;
