import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://cd.cgseconno.in/graphql",
});

const authLink = setContext((_, { headers }) => {
  const newToken = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbFZlcmlmaWVkIjp0cnVlLCJ0eXBlIjoiVVNFUiIsIm9uQm9hcmRpbmciOmZhbHNlLCJfaWQiOiI2MDAyZDFhY2VhY2FiODBmNGM3NWE1OWQiLCJuYW1lIjoiU29oYWlsIiwiZW1haWwiOiJzb2hhaWxAbWFpbGluYXRvci5jb20iLCJjaGFuZ2VFbWFpbCI6InNoeWFtQG1haWxpbmF0b3IuY29tIiwiY3JlYXRlZEF0IjoiMjAyMS0wMS0xNlQxMTo0NDo0NC45NTdaIiwidXBkYXRlZEF0IjoiMjAyMS0wMS0yNlQxMDoxMjowMy40MjVaIiwibG9nbyI6IjIwMjEwMTI2LVRBVC01NDg5NzAzLmpwZWciLCJpYXQiOjE2MTMwMzg1MTEsImV4cCI6MTYxMzY0MzMxMX0.XwPMVjxQAdGZtARtZhFTYdHPHgC-yEoc79AXRSsAQiw`;
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${newToken}`,
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});
export default client;
