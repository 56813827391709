import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dropdown, Icon, Input, Search, Tab } from "semantic-ui-react";
import { AccountLogin } from "../apollo/Mutation/AccountLogin";
import { GET_CASES } from "../apollo/Queries/GET_CASES";
import { Places } from "../apollo/Queries/Places";
import CaseDetailList from "./CaseDetailsList";

const CaseDetails = () => {
  const [state, setState] = useState({
    value: "ADVOCATE",
    searchResult: "",
    searchText: "",
    locationText: "",
    locationResult: "",
    entryType: "",
    context: "",
    activeIndex: "",
  });
  let page = 1;
  let limit = 20;

  const options = [
    { key: "advocate", text: "ADVOCATE", value: "ADVOCATE" },
    { key: "cnr", text: "CNR", value: "CNR" },
    { key: "Client", text: "CLIENT", value: "CLIENT" },
  ];

  const handleChange = (e, data) => {
    setState({ ...state, value: data.value });
  };

  const [getCases, { data, loading, fetchMore }] = useLazyQuery(GET_CASES, {
    onCompleted: (data) => {
      console.log(data);
      setState({ ...state, searchResult: data.getCases.payload.caseName });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [getPlaces, { loading: PlacesLoading }] = useLazyQuery(Places, {
    onCompleted: (data) => {
      const locations = data.places.payload.map((loc) => {
        return { title: loc.name.toString(), price: loc.entryType };
      });
      setState({ ...state, locationResult: locations });
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const payload = data?.getCases?.payload || [];
  const hasMore = data?.getCases?.count > payload?.length;

  useEffect(() => {
    if (state.locationText !== "" && state.searchText !== "") {
      getCases({
        variables: {
          page: page,
          limit: limit,
          search: {
            text: state.searchText,
            searchBy: state.value,
            location: state.locationText,
            entryType: state.entryType ? state.entryType : "DISTRICT_COURT",
          },
        },
      });
    }
  }, [state.entryType]);
  const panes = [
    {
      menuItem: "District Court",
      render: () => (
        <Tab.Pane>
          <CaseDetailList
            payload={payload}
            fetchMore={fetchMore}
            activeIndex={state.activeIndex}
            hasMore={hasMore}
            limit={limit}
          />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "High Court",
      render: () => (
        <Tab.Pane>
          {" "}
          <CaseDetailList
            payload={payload}
            fetchMore={fetchMore}
            hasMore={hasMore}
            activeIndex={state.activeIndex}
            limit={limit}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "NCDRC",
      render: () => (
        <Tab.Pane>
          <CaseDetailList
            payload={payload}
            fetchMore={fetchMore}
            hasMore={hasMore}
            activeIndex={state.activeIndex}
            limit={limit}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "District Forum",
      render: () => (
        <Tab.Pane>
          {" "}
          <CaseDetailList
            payload={payload}
            fetchMore={fetchMore}
            hasMore={hasMore}
            limit={limit}
            activeIndex={state.activeIndex}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "State Commission",
      render: () => (
        <Tab.Pane>
          {" "}
          <CaseDetailList
            payload={payload}
            fetchMore={fetchMore}
            hasMore={hasMore}
            activeIndex={state.activeIndex}
            limit={limit}
          />
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChnage = (e, { activeIndex }) => {
    console.log(activeIndex);
    if (activeIndex === 0) setState({ ...state, entryType: "DISTRICT_COURT" });
    if (activeIndex === 1) setState({ ...state, entryType: "HIGH_COURT" });
    if (activeIndex === 2) setState({ ...state, entryType: "NCDRC" });
    if (activeIndex === 3)
      setState({ ...state, entryType: "NCDRC_DISTRICT_FORUM" });
    if (activeIndex === 4)
      setState({ ...state, entryType: "NCDRC_STATE_COMMISSION" });
  };
  return (
    <div style={{ margin: 30 }}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Search
          value={state.locationText}
          name="location"
          loading={PlacesLoading}
          onSearchChange={(e, data) => {
            setState({ ...state, locationText: data.value });
            if (data.value.length > 3) {
              getPlaces({
                variables: {
                  page,
                  limit,
                  search: data.value,
                },
              });
            }
          }}
          placeholder="Enter location here"
          results={state.locationResult}
          onResultSelect={(e, data) =>
            setState({ ...state, locationText: data.result.title })
          }
        />
        <Dropdown
          search
          selection
          clearable
          value={state.value}
          options={options}
          onChange={handleChange}
        />
      </div>
      <div style={{ margin: 30 }}>
        <span style={{ marginBottom: 40, fontWeight: 600, fontSize: 18 }}>
          {state.value === "ADVOCATE" ? "Advocate Name :" : null}
          {state.value === "CLIENT" ? "Client Name :" : null}
          {state.value === "CNR" ? "CNR Number :" : null}
        </span>
        <Input
          loading={loading}
          fluid
          style={{ marginTop: 20 }}
          onChange={(e, data) => setState({ ...state, searchText: data.value })}
          label={
            <Button
              loading={loading}
              name="search"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (state.searchText.length > 5) {
                  if (state.locationText === "") {
                    toast.error("Please enter location");
                  } else {
                    getCases({
                      variables: {
                        page: page,
                        limit: limit,
                        search: {
                          text: state.searchText,
                          searchBy: state.value,
                          location: state.locationText,
                          entryType: state.entryType
                            ? state.entryType
                            : "DISTRICT_COURT",
                        },
                      },
                    });
                  }
                } else {
                  toast.error(
                    `Please enter ${
                      state.value === "CNR"
                        ? "CNR Number"
                        : `${state.value} Name`
                    } `
                  );
                }
              }}
            >
              Search
            </Button>
          }
          labelPosition="right"
          placeholder="Search here..."
          value={state.searchText}
        />
      </div>
      <Tab panes={panes} style={{ margin: 20 }} onTabChange={handleTabChnage} />
    </div>
  );
};
export default CaseDetails;
