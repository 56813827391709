import { gql } from "@apollo/client";

export const V2NEWS_ANALYZER = gql`
 query v2newsAnalyzer($id: ID){
  v2newsAnalyzer(id: $id){
    payload{
     reason
      title
      date
      agency
      isoDate
      link
    }
  }
}
`;
