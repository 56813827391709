import { useMutation, useQuery } from "@apollo/client";
import React, { useRef, useState } from "react";
import moment from "moment";
import {
  Button,
  Form,
  Loader,
  Radio,
  Segment,
  Table,
  Header,
  Icon,
  Modal,
  Input,
} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Coupons } from "../apollo/Queries/Coupons";
import { UpdateCoupon } from "../apollo/Mutation/CouponUpdate";
import { toast } from "react-toastify";
import { DeleteCoupon } from "../apollo/Mutation/CouponDelete";
import { CreateCoupon } from "../apollo/Mutation/CouponCreate";
import ContentEditable from "react-contenteditable";

const CouponPage = () => {
  let page = 1;
  let limit = 20;

  const { data, loading, error, fetchMore } = useQuery(Coupons, {
    variables: {
      page,
      limit,
    },
  });

  if (loading) return <Loader active inline="centered" />;
  if (error) return <h3>{error.message}</h3>;
  const payload = data?.coupons?.payload;
  const hasMore = data?.coupons?.count > payload?.length;
  return (
    <React.Fragment>
      <Form>
        <div style={styles.tableContainer}>
          <Segment>
            <InfiniteScroll
              dataLength={payload?.length} //This is important field to render the next data
              next={() => {
                fetchMore({
                  variables: {
                    page: parseInt(payload?.length / limit, 10) + 1,
                    limit,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      coupons: {
                        ...prev.coupons,
                        payload: [
                          ...prev.coupons.payload,
                          ...fetchMoreResult.coupons.payload,
                        ],
                      },
                    });
                  },
                });
              }}
              hasMore={hasMore}
              loader={
                <Loader
                  active
                  inline="centered"
                  style={{ overflow: "hidden" }}
                />
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="11">
                      <span
                        style={{
                          letterSpacing: 1,
                          fontSize: 15,
                        }}
                      >
                        Coupons <CreateCoupons />
                      </span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell width="1">Name</Table.Cell>
                    <Table.Cell width="1">Prefix</Table.Cell>
                    <Table.Cell width="1">Amount</Table.Cell>
                    <Table.Cell width="1">min Txn Limit</Table.Cell>
                    <Table.Cell width="1">Used</Table.Cell>
                    <Table.Cell width="1">Created At</Table.Cell>
                    <Table.Cell width="1">Used Date</Table.Cell>
                    <Table.Cell width="1">Action</Table.Cell>
                    <Table.Cell width="1">Delete</Table.Cell>
                  </Table.Row>

                  {payload?.map((coupon, i) => (
                    <CouponListItem key={i} coupon={coupon} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </Segment>
        </div>
      </Form>
    </React.Fragment>
  );
};

const styles = {
  tableContainer: {
    marginTop: 35,

    paddingLeft: 10,
    height: "80vh",
  },
};
export default CouponPage;

const CouponListItem = ({ coupon }) => {
  const [couponDelete, { loading: deleteLoading }] = useMutation(DeleteCoupon, {
    onCompleted: (data) => {
      console.log(data);
      toast.success(data.deleteCoupon.message);
    },
    onError: (error) => {
      toast.error(
        "",
        error.message.replace("GraphQL error:" || "Network error:", " ").trim(),
        1500,
        () => {
          alert("callback");
        }
      );
    },
    update: (
      cache,
      {
        data: {
          deleteCoupon: { payload },
        },
      }
    ) => {
      cache.modify({
        fields: {
          coupons(existingCoupons = {}, { readField }) {
            console.log(payload);
            const filteredPayload = existingCoupons?.payload?.filter(
              (caseRef) => payload[0]._id !== readField("_id", caseRef)
            );
            return {
              ...existingCoupons,
              payload: filteredPayload,
            };
          },
        },
      });
    },
  });

  const [couponUpdate, {}] = useMutation(UpdateCoupon, {
    onCompleted: (data) => {
      toast.success(data.updateCoupon.message);
    },
    onError: (error) => {
      toast.error(
        "",
        error.message.replace("GraphQL error:" || "Network error:", " ").trim(),
        1500,
        () => {
          alert("callback");
        }
      );
    },
  });
  const text = useRef(coupon?.minTxnLimit?.toString());

  const handleChange = (evt) => {
    const value = String(
      evt.target.value
        .replace(/&nbsp;/gi, "")
        .replace(/<div><br><\/div>/gi, "")
        .replace(/<p><br><\/p>/gi, "")
    ).trim();
    console.log(value);
    if (value !== "") {
      text.current = value;
      couponUpdate({
        variables: {
          couponId: coupon._id,
          updateDetail: {
            minTxnLimit: parseInt(value, 10),
          },
        },
      });
    }
  };
  return (
    <Table.Row>
      <Table.Cell width="1">{coupon.name}</Table.Cell>
      <Table.Cell width="1">{coupon.prefix}</Table.Cell>
      <Table.Cell width="1">{coupon.amount}</Table.Cell>
      <Table.Cell width="1">
        <ContentEditable html={text.current} onChange={handleChange} />
      </Table.Cell>

      <Table.Cell width="1">{coupon.used ? "used" : "not used"}</Table.Cell>

      <Table.Cell width="1">
        {moment(parseInt(coupon.createdAt)).format("lll")}
      </Table.Cell>
      <Table.Cell width="1">
        {coupon.used
          ? moment(parseInt(coupon.updatedAt)).format("lll")
          : "-----"}
      </Table.Cell>
      <Table.Cell width="1">
        <Radio
          toggle
          checked={coupon.used}
          onChange={(e, data) => {
            couponUpdate({
              variables: {
                couponId: coupon._id,
                updateDetail: {
                  used: data.checked,
                },
              },
            });
          }}
        />
      </Table.Cell>
      <Table.Cell width="1">
        <Button
          color="red"
          loading={deleteLoading}
          onClick={() => {
            couponDelete({
              variables: {
                couponId: coupon._id,
              },
            });
          }}
        >
          Delete
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

const CreateCoupons = () => {
  const [open, setOpen] = React.useState(false);
  const [coupon, setCoupon] = useState({
    amount: "",
    howMany: "",
    prefix: "",
    minTxnLimit: "",
  });

  const [couponCreate, {}] = useMutation(CreateCoupon, {
    onCompleted: (data) => {
      toast.success(data.createCoupon.message);
      setOpen(false);
    },
    onError: (error) => {
      toast.error(
        "",
        error.message.replace("GraphQL error:" || "Network error:", " ").trim(),
        1500,
        () => {
          alert("callback");
        }
      );
    },
    update: (
      cache,
      {
        data: {
          createCoupon: { payload },
        },
      }
    ) => {
      cache.modify({
        fields: {
          coupons(existingCoupons = {}, { readField }) {
            return {
              ...existingCoupons,
              payload: [...payload, ...existingCoupons.payload],
            };
          },
        },
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(coupon);
    couponCreate({
      variables: {
        amount: parseInt(coupon.amount, 10),
        howMany: parseInt(coupon.howMany, 10),
        minTxnLimit: parseInt(coupon.minTxnLimit, 10),
        prefix: coupon.prefix.toUpperCase(),
      },
    });
  };
  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={<Button icon="plus"></Button>}
    >
      <Header icon>Create Coupons</Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Coupons Amount</label>
            <Input
              placeholder="Enter Coupon Amount"
              required
              value={coupon.amount}
              type="number"
              onChange={(e, data) =>
                setCoupon({ ...coupon, amount: data.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Coupon Quantity</label>
            <Input
              placeholder="Enter Coupon Quantity"
              required
              value={coupon.howMany}
              type="number"
              onChange={(e, data) =>
                setCoupon({ ...coupon, howMany: data.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Coupon Min Txn Limit</label>
            <Input
              placeholder="Enter Coupon Min Txn Limit"
              required
              value={coupon.minTxnLimit}
              type="number"
              onChange={(e, data) =>
                setCoupon({ ...coupon, minTxnLimit: data.value })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Coupon Prefix</label>
            <Input
              placeholder="Coupon Prefix"
              required
              value={coupon.prefix}
              type="text"
              onChange={(e, data) =>
                setCoupon({ ...coupon, prefix: data.value.toUpperCase() })
              }
            />
          </Form.Field>
          <Button type="submit">Submit</Button>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> No
        </Button>
        <Button color="green" inverted onClick={() => setOpen(false)}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
