import { gql } from "@apollo/client";

export const Accounts = gql`
  query accounts($page: Int, $limit: Int) {
    accounts(page: $page, limit: $limit) {
      payload {
        _id
        name
        email
        type
        googleId
        location
        number
        onBoarding
        caseCount
        createdAt
      }
      count
    }
  }
`;
