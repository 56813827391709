const { gql } = require("@apollo/client");

export const AccountLogin = gql`
  mutation accountLogin(
    $email: String!
    $password: String!
    $fcmToken: String
  ) {
    accountLogin(email: $email, password: $password, fcmToken: $fcmToken) {
      payload {
        _id
        name
        email
        emailVerified
        password
        type
        logo
        emailToken
        enable
        acceptTnC
        createdAt
        updatedAt
      }
      status
      token
      message
    }
  }
`;
