const { gql } = require("@apollo/client");

export const CreateCoupon = gql`
  mutation createCoupon(
    $amount: Int!
    $howMany: Int!
    $prefix: String!
    $minTxnLimit: Int!
  ) {
    createCoupon(
      amount: $amount
      howMany: $howMany
      prefix: $prefix
      minTxnLimit: $minTxnLimit
    ) {
      payload {
        _id
        name
        prefix
        amount
        minTxnLimit
        used
        createdAt
        updatedAt
      }
      token
      message
    }
  }
`;
