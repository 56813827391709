const { gql } = require("@apollo/client");

export const UpdateCoupon = gql`
  mutation updateCoupon($couponId: ID!, $updateDetail: CouponUpdate) {
    updateCoupon(couponId: $couponId, updateDetail: $updateDetail) {
      payload {
        _id
        name
        prefix
        amount
        minTxnLimit
        used
        createdAt
        updatedAt
      }
      token
      message
    }
  }
`;
