import { useMutation } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { AccountLogin } from "../apollo/Mutation/AccountLogin";

const DashboardLayout = (props) => {
  const [logIn, { loading: LoginLoading }] = useMutation(AccountLogin, {
    onCompleted: (data) => {
      localStorage.setItem("token", data.accountLogin.token);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: 20 }}>
          <Link to={"/"}>
            <Button>Back</Button>
          </Link>
        </div>
        <div>
          <Link to={"/accounts"}>
            <Button size="large">Accounts </Button>
          </Link>
          <Link to={"/coupons"}>
            <Button size="large">Coupons </Button>
          </Link>
          <Button
            size="large"
            loading={LoginLoading}
            onClick={() => {
              logIn({
                variables: {
                  email: "sohail@mailinator.com",
                  password: "123456",
                },
              });
            }}
          >
            Login{" "}
          </Button>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default DashboardLayout;
