const { gql } = require("@apollo/client");

export const DeleteCoupon = gql`
  mutation deleteCoupon($couponId: ID!) {
    deleteCoupon(couponId: $couponId) {
      payload {
        _id
        name
        prefix
        amount
        minTxnLimit
        used
        createdAt
        updatedAt
      }
      token
      message
    }
  }
`;
