import React from "react";
import { Divider } from "semantic-ui-react";

const CaseDetailListItems = ({ pay }) => {
  return (
    <div
      style={{
        borderRadius: 8,
        backgroundColor: "#fff",
        marginBottom: 20,
        boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
        WebkitBoxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
      }}
    >
      <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              width: 15,
              height: 15,
              borderRadius: 15,
              backgroundColor: "#2ec114",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              color: "#354052",
              fontWeight: "bold",
              fontSize: "1.1rem",
              flex: 1,
            }}
          >
            <div>{pay.caseName}</div>
          </div>

          <div style={{ marginLeft: 30, width: 120 }}>
            <div style={{ fontSize: "0.9rem", color: "gray" }}>Next Date</div>
            <div style={{ color: "#354052", fontWeight: 700 }}>
              {pay.history
                ? pay.history[pay.history?.length - 1].nextHearingDate
                : null}
            </div>
          </div>
        </div>
      </div>
      <Divider style={{ margin: 0, marginLeft: 20, marginRight: 20 }} />
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div style={{}}>
            <div style={{ color: "#7F8FA4", fontSize: "0.8rem" }}>
              Case No :
            </div>
            <div
              style={{
                fontSize: "1rem",
                color: "#4990df",
              }}
            >
              {pay.filingNumber}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flex: 1,
            }}
          >
            <div style={{ marginLeft: 20 }}>
              <div style={{ color: "#7F8FA4", fontSize: "0.8rem" }}>
                Advocate :
              </div>
              <div
                style={{
                  color: "#354052",
                  fontSize: "1rem",
                }}
              >
                {pay.advocates}
              </div>
            </div>

            <div style={{ marginLeft: 20 }}>
              <div style={{ color: "#7F8FA4", fontSize: "0.8rem" }}>
                Complainant Name:
              </div>
              <div
                style={{
                  color: "#354052",
                  fontSize: "1rem",
                }}
              >
                {pay.complainantName}
              </div>
            </div>

            <div style={{ marginLeft: 20 }}>
              <div style={{ color: "#7F8FA4", fontSize: "0.8rem" }}>
                Case Type:
              </div>
              <div
                style={{
                  color: "#354052",
                  fontSize: "1rem",
                }}
              >
                {pay.caseType}
              </div>
            </div>
          </div>

          <div style={{ marginLeft: 20 }}>
            <div style={{ color: "#7F8FA4", fontSize: "0.8rem" }}>Location</div>
            <div
              style={{
                color: "#354052",
                fontSize: "1rem",
              }}
            >
              {pay.location}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaseDetailListItems;
