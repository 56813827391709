/* eslint-disable react/jsx-no-target-blank */
import { useQuery } from "@apollo/client";
import moment from "moment";
import React from "react";
import { List, Loader } from "semantic-ui-react";
import { V2NEWS_ANALYZER } from "../apollo/Queries/NewsAnalyzer";

const NewsAnalyzerPage = () => {
  const { data, loading, error, fetchMore } = useQuery(V2NEWS_ANALYZER, {});

  if (loading) return <Loader active inline="centered" />;
  if (error) return <h3>{error.message}</h3>;
  const payload = data?.v2newsAnalyzer?.payload;
  return (
    <div style={{marginTop:50}}>
      <h1>Media Agency Publication </h1>
      <List divided relaxed>
        {payload.map((news) => {
          const newNews = news.map((n) => ({
            ...n,
            isoDate: new Date(n.isoDate),
          }));
          console.log(newNews);
          const sortedNews = newNews.sort((a, b) => a.isoDate - b.isoDate);

          return (
            <List.Item className="my-list">
              <List.Content>
                <List.Header as="a">
                  <a style={{color:'#8AB4F7',fontWeight:600,fontSize:"1.2rem"}} target="_blank" href={sortedNews[0].link}>
                    {sortedNews[0].title}
                  </a>
                </List.Header>
                <List.Description as="a">
                {/* <div>{moment(sortedNews[0].isoDate).format("lll")}</div> */}
                  <div style={{  }}>
                    {sortedNews.map((agency) => {
                      return (
                        <div className="  my-card">
                          <div className=" card">
                            <div className="content">
                              <div style={{fontWeight:500}} className="text-over"><a target="_blank" href={agency.title}>{agency.agency}</a></div>
                              <div>Published At:-{moment(agency.isoDate).format(" Do MMMM  YYYY, h:mm:ss a")}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};
export default NewsAnalyzerPage;
